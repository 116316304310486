export const firebaseConfig = {
  apiKey: "AIzaSyD0mRc8xyt-3PJ6Y0pJ_kqbzn1GS7cIfcI",
  authDomain: "naruhiro-portfolio.firebaseapp.com",
  databaseURL: "https://naruhiro-portfolio.firebaseio.com",
  projectId: "naruhiro-portfolio",
  storageBucket: "naruhiro-portfolio.appspot.com",
  messagingSenderId: "962775403353",
  appId: "1:962775403353:web:6d98e12c602245dab1da48",
  measurementId: "G-SLXRCH15HH"
};
